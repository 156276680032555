import React from "react";
import entoto from "./../../assets/Images/entoto.jpg";
import ertaaleDesk from "./../../assets/Images/erta-ale-desktop.jpg";
import ertaaleTab from "./../../assets/Images/erta-ale-tab.png";
import ertaaleMobile from "./../../assets/Images/ertale-mobile.png";
import addisAbabaDesk from "./../../assets/Images/addis-ababa-desktop.jpg";
import addisAbabaTab from "./../../assets/Images/addis-ababa-tab.png";
import addisMobile from "./../../assets/Images/sheger-mobile.png";
import makepay from "./../../assets/Images/make-pay.svg";
import download from "./../../assets/Images/download-visa.svg";
import checkstatus from "./../../assets/Images/check-status.svg";
import touristvisaImg from "./../../assets/Images/tourist-visa.jpg";
import visaImg from "./../../assets/Images/visa.jpg";
import businessvisaImg from "./../../assets/Images/business-visa.jpg";
import AratKiloDesk from "./../../assets/Images/4kilo.jpg";
import AratKiloTab from "./../../assets/Images/4kilo-tab.jpg";
import AratKiloMobile from "./../../assets/Images/4kilo-mobile.png";
import { NavLink } from "react-router-dom";

const Index = () => {
  return (
    <div>
      <div
        id="carouselExampleFade"
        class="carousel slide carousel-fade"
        data-bs-ride="carousel"
        data-interval="200"
      >
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img
              src={AratKiloDesk}
              class="d-block w-100 desktop-slide-img"
              height={510}
              alt="First Slide"
            />
                        <img
              src={AratKiloTab}
              class="d-none w-100 tab-slide-img"
              height={510}
              alt="First Slide"
            />
                                   <img
              src={AratKiloMobile}
              class="d-none w-100 mobile-slide-img"
              height={510}
              alt="First Slide"
            />
            <div class="carousel-caption align-left">
              <h1
                className="align-lft courasel-caption-text"
                style={{ marginTop: "-220px", fontSize: "47px" }}
              >
                Addis Ababa, the capital and vibrant city of Ethiopia.
              </h1>
              <NavLink
                className="btn evisa-courasel-btn btn-md px-5 mt-4"
                to="/visa"
              >
                <h5>
                  <b>Apply Now</b>
                </h5>
              </NavLink>
            </div>
          </div>
          <div class="carousel-item">
            <img
              src={ertaaleDesk}
              class="d-block w-100 desktop-slide-img"
              height={510}
              alt="Second Slide"
            />
                        <img
              src={ertaaleTab}
              class="d-none w-100 tab-slide-img"
              height={510}
              alt="Second Slide"
            />
                                    <img
              src={ertaaleMobile}
              class="d-none w-100 mobile-slide-img"
              height={510}
              alt="Second Slide"
            />
            <div class="carousel-caption align-left">
              <h1
                className="align-lft courasel-caption-text"
                style={{ marginTop: "-220px", fontSize: "47px" }}
              >
                The Continuously Active Basaltic Shield Volcano Of Erta Ale.
              </h1>
              <NavLink
                className="btn  btn-md px-5 evisa-courasel-btn mt-4"
                to="/visa"
              >
                <h5>
                  <b>Apply Now</b>
                </h5>
              </NavLink>{" "}
            </div>
          </div>
          <div class="carousel-item">
            <img
              src={addisAbabaDesk}
              class="d-block w-100 desktop-slide-img"
              height={510}
              alt="Third Slide"
            />
                        <img
              src={addisAbabaTab}
              class="d-none w-100 tab-slide-img"
              height={510}
              alt="Third Slide"
            />
                                    <img
              src={addisMobile}
              class="d-none w-100 mobile-slide-img"
              height={510}
              alt="Third Slide"
            />
            <div class="carousel-caption align-left">
              <h1
                className="align-lft courasel-caption-text"
                style={{ marginTop: "-220px", fontSize: "47px" }}
              >
                Addis Ababa, the capital and vibrant city of Ethiopia.
              </h1>
              <NavLink
                className=" btn evisa-courasel-btn btn-md px-5 mt-4"
                to="/visa"
              >
                <h5>
                  <b>Apply Now</b>
                </h5>
              </NavLink>
            </div>
          </div>
        </div>
        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="prev"
        >
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="next"
        >
          <span class="visually-hidden">Next</span>
        </button>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-md-4 evisa-link-card">
          <div class="card border-0" style={{ width: "10rem", margin: "0 auto", float: "none"}}>
              <NavLink className="nav-link" to="/continue-payment">
                <img
                  src={makepay}
                  class="card-img-top-download right"
                  alt="Continue to a Payment"
                />
                <div class="card-body">
                  <b className="text-dark visa-nav-item">
                    Continue to a Payment
                  </b>
                </div>
              </NavLink>
            </div>
          </div>

          <div className="col-md-4 evisa-link-card">
            <div class="card border-0" style={{ width: "10rem", margin: "0 auto", float: "none"}}>
              <NavLink className="nav-link" to="/download-visa">
                <img
                  src={download}
                  width="30"
                  class="card-img-top-download"
                  alt="Download Visa"
                />
                <div class="card-body">
                  <b className="text-dark visa-nav-item">Download Visa</b>
                </div>
              </NavLink>
            </div>
          </div>

          <div className="col-md-4 evisa-link-card">
          <div class="card border-0 mb-3" style={{ width: "10rem", margin: "0 auto", float: "none"}}>
              <NavLink className="nav-link" to="/status">
                <img
                  src={checkstatus}
                  width="30"
                  class="card-img-top-download"
                  alt="Check Status"
                />
                <div class="card-body">
                  <b className="text-dark visa-nav-item">Check Status</b>
                </div>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="container">
          <div className="row" >
            <div className="col-md-4">
            <div class="card border-0 mb-3" style={{ maxWidth: "21.5rem", margin: "0 auto", float: "none",  boxShadow: "0px 1px 5px 1px #d9d9d9",}}>

                <img
                  src={touristvisaImg}
                  class="card-img-top-visa-card"
                  alt="tourist visa"
                />{" "}
                <div class="card-body">
                  <h5 class="card-title align-left">
                    <b>Tourist Visa</b>
                  </h5>
                  <p class="card-text align-left">
                    A tourist visa is an electronic single-entry visa issued by
                    the Ethiopian government..
                  </p>
                  <NavLink
                    className=" btn btn-outline-primary  align-left"
                    to="/information/tourist"
                  >
                    See More
                  </NavLink>{" "}
                </div>
              </div>
            </div>
            <div className="col-md-4">
            <div class="card border-0 mb-3" style={{ maxWidth: "21.5rem", margin: "0 auto", float: "none",  boxShadow: "0px 1px 5px 1px #d9d9d9",}}>
                <img
                  src={businessvisaImg}
                  class="card-img-top-visa-card"
                  alt="business visa"
                />{" "}
                <div class="card-body">
                  <h5 class="card-title align-left">
                    <b>Business Visa</b>
                  </h5>
                  <p class="card-text align-left">
                    If you are applying for urgent services, you must have a
                    reason or evidence like..
                  </p>
                  <NavLink
                    className=" btn btn-outline-primary  align-left"
                    to="/information/InvestmentVisa"
                  >
                    See More
                  </NavLink>{" "}
                </div>
              </div>
            </div>
            <div className="col-md-4">
            <div class="card border-0 mb-3" style={{ maxWidth: "21.5rem", margin: "0 auto", float: "none",  boxShadow: "0px 1px 5px 1px #d9d9d9",}}>
                <img
                  src={visaImg}
                  class="card-img-top-visa-card"
                  alt="visa requirment"
                />{" "}
                <div class="card-body">
                  <h5 class="card-title align-left">
                    <b>Visa Requirment</b>
                  </h5>
                  <p class="card-text align-left">
                  Please take a look at the Ethiopian e-Visa requirements. Use the below link for detail.
                                                                        </p>
                  <NavLink
                    className="btn btn-outline-primary  align-left"
                    to="/information"
                  >
                    See More
                  </NavLink>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
        <div style={{ backgroundColor: "#0D4D99" }}>
          <div className="container-fluid">
            <br />
            <div className="row">
              <div className="col-md-3"></div>
              <div className="col-md-6">
                <h3 className="text-white text-center m-3">
                  <b>Are you planning to extend Visa</b>{" "}
                </h3>
                <p className="text-center text-white">
                  The visa extension service is a service for foreign nationals
                  with a valid Investment visa, Government Employment visa,
                  Government Institutions Short Task Visa, Foreign Business Firm
                  Employment Visa, International Organizations/Embassies Work
                  Visa, Ethiopian Private Business Firm Work Visa, and Tourist
                  Visa who needed to prolong their visas on reasonable grounds.{" "}
                </p>
                <NavLink
                  className=" btn btn-outline-primary text-white border-white px-5 m-1"
                  to="/visa-extension"
                >
                  <b>Extend Now</b>
                </NavLink>
              </div>
              <div className="col-md-3"></div>
            </div>
            <br />
          </div>
        </div>
        <br />
        <br />
        <br />
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-3"></div>
            <div className="col-md-6">
              <h3 className="text-center">
                <b>Things to Do</b>
              </h3>
              <p className="text-center m-3">
                Ethiopia provides a wide range of activities for nature
                enthusiasts and interested tourists, including hiking and
                wildlife viewing as well as hot-air balloon rides and museum
                exploration{" "}
              </p>
            </div>
            <div className="col-md-3"></div>
          </div>
        </div>
        <br />
        <br />
        <div className="container">
          <div className="row">
          <div className="col-sm-6 col-md-6 col-lg-3 col-xl-3 col-xxl-3">
              <div
                class="card accordion-historical border-0  mb-3"
                style={{height: "28rem" }}
              >
                <NavLink
                  to="/historicalplace"
                  style={{
                    color: "white",
                  }}
                >
                  <i
                    className="pi pi-external-link"
                    style={{ fontSize: "1em" }}
                  ></i>
                </NavLink>

                <section class="accordion-item-historical">
                  <div class="card-body accordion-item-historical-content">
                    <h5 class="evisa-thingstodo-title">
                      Historical Experience
                    </h5>
                    <p class="card-text-historical-hover-phar">
                    Ethiopia, in the Horn of Africa, is a rugged, landlocked country split by the Great Rift Valley. With archaeological finds dating back more than 3 million years,
                     it's a place of ancient culture. Among its important sites are Lalibela with its rock-cut Christian churches from the 12th-13th centuries. 
                     Aksum is the ruins of an ancient city with obelisks, tombs, castles and Our Lady Mary of Zion church. 
                    </p>
                  </div>
                </section>
              </div>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-3 col-xl-3 col-xxl-3">
              <div
                class="card accordion-natural border-0  mb-3"
                style={{height: "28rem" }}
              >
                <NavLink
                  to="/historicalplace"
                  style={{
                    color: "white",
                  }}
                >
                  <i
                    className="pi pi-external-link"
                    style={{ fontSize: "1em" }}
                  ></i>
                </NavLink>

                <section class="accordion-item-natural">
                  <div class="card-body accordion-item-natural-content">
                    <h5 class="evisa-thingstodo-title">Natural Experience</h5>
                    <p class="card-text-natural-hover-phar">
                    With is dramatic scenery, rich biodiversity and varied network of protected areas,
                     Ethiopia is a nature lovers paradise, and well known for its high number of
                      endemics (species found nowhere else in the world).
                    </p>
                  </div>
                </section>
              </div>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-3 col-xl-3 col-xxl-3">
              <div
                class="card accordion-advanture border-0  mb-3"
                style={{height: "28rem" }}
              >
                <NavLink
                  to="/historicalplace"
                  style={{

                    color: "white",
                  }}
                >
                  <i
                    className="pi pi-external-link"
                    style={{ fontSize: "1em" }}
                  ></i>
                </NavLink>

                <section class="accordion-item-advanture">
                  <div class="card-body accordion-item-advanture-content">
                    <h5 class="evisa-thingstodo-title">
                      Outdoor and Adventure
                    </h5>
                    <p class="card-text-advanture-hover-phar">
                    Ethiopia is renowned as one of Africa's top high altitude hiking and trekking destinations,
                     but a range of other outdoor activities is on after, from climbing the spectacular 
                     Arta Ale volcano to ballooning above the Great Rift Valley.
                    </p>
                  </div>
                </section>
              </div>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-3 col-xl-3 col-xxl-3">
              <div
                class="card accordion-festival border-0  mb-3"
                style={{height: "28rem" }}
              >
                <NavLink
                  to="/ethiopianfestival"
                  style={{

                    color: "white",
                  }}
                >
                  <i
                    className="pi pi-external-link"
                    style={{ fontSize: "1em" }}
                  ></i>
                </NavLink>

                <section class="accordion-item-festival">
                  <div class="card-body accordion-item-festival-content">
                    <h5 class="evisa-thingstodo-title">
                      Activities and Festivals
                    </h5>
                    <p class="card-text-hover-phar">
                    Ethiopian festival Celebrations are great and colorful events, mostly religious, and frequently take place over several days.
                     Important Christian holidays include Meskel, Christmas, Timkat, Kiddus Yohannes and Easter. And other widely celebrated festivals are Irreechaa and ashenda.
                    </p>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
        <div className="row" style={{margin:"0px",padding:"0px"}}>
          <div className="col-md-2"></div>
          <div className="col-md-8">
            <h3 className="text-center">
            <b>Important Notice</b>
              </h3>
                <p className="mx-5 text-center">
                  We have come to know that there are some fraud websites which
                  have similar look and feel with our official{" "}
                  <a
                    data-sf-ec-immutable=""
                    href="https://www.evisa.gov.et"
                    class="text-primary"
                  >
                    e-Visa website.{" "}
                  </a>{" "}
                  Please note that the Ethiopian Government doesn't have any
                  agreement with the owners of those websites.Visa application
                  submitted or payment made through these websites is not
                  acceptable. Please do not submit visa application on these
                  sites:{" "}
                  <a
            href="http://www.ethiopiaevisa.com"
            class="text-primary"target="_blank" rel="noreferrer"
          >
            www.ethiopiaevisa.com ,
          </a>{" "}
          <a
            data-sf-ec-immutable=""
            href="https://www.ethiopiaimmigration.org/"
            class="text-primary" target="_blank" rel="noreferrer"
          >
            www.ethiopiaimmigration.org ,
          </a>
          <a
            data-sf-ec-immutable=""
            href="http://www.ethiopiaonlinevisa.com"
            class="text-primary" target="_blank" rel="noreferrer"
          >
            www.ethiopiaonlinevisa.com ,
          </a>{" "}
          <a
            data-sf-ec-immutable=""
            href="http://www.evisaforethiopia.com"
            class="text-primary" target="_blank" rel="noreferrer"
          >
            www.evisaforethiopia.com,
          </a>{" "}
          <a
            data-sf-ec-immutable=""
            href="https://ethiopian-visa.com/"
            class="text-primary" target="_blank" rel="noreferrer"
          >
            www.ethiopian-visa.com,
          </a>{" "}
          <a
            data-sf-ec-immutable=""
            href="https://www.ethiopia-evisa.com"
            class="text-primary" target="_blank" rel="noreferrer"
          >
            www.ethiopia-evisa.com,
          </a>{" "}
          <a
            data-sf-ec-immutable=""
            href="https://www.ethiopiavisas.net/"
            class="text-primary" target="_blank" rel="noreferrer"
          >
           www.ethiopiavisas.net
          </a>{" "}
                  or others. The Government of Ethiopia is not responsible for
                  any payments made or for the inconveniences this might create
                  on the applicants.
                </p>{" "}
          </div>
          <div className="col-md-2"></div>
        </div>
        <br />
        <br />
      </div>
    </div>
  );
};

export default Index;
